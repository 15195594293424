// src/components/app.tsx
// Import necessary dependencies
import React from 'react'
import { Button } from 'react-bootstrap';
import history from './../history';
import "./Home.css";

import { ReactComponent as Frame } from './../img/Frame.svg';
import { ReactComponent as Group8586 } from './../img/Group 8586.svg';
import { ReactComponent as Group } from './../img/Group.svg';

import vector from './../img/Vector.png';

// Create App component
function Home() {
  return (
    <div className="app">
      <header>
        {/*<img className="Logo" src="./../img/Frame.svg" alt="" />*/}
        <Frame className="Logo" />
        <h1>
          Ivanti | Wavelink <br />
          Device Self Validation Portal
        </h1>
      </header>
      <section className="info">
        <h4>
          Welcome, this is the first step in getting your device validated to work with Velocity. <br /> Please take your time answering a few questions for us. This information will be used to register you as well as provide information we will need to add your device to our solutions site.
        </h4>
        {/*<button type=button onclick="location.href='contact.html'" value='click here'>Get Started</button>*/}
        <Button variant="btn" onClick={() => history.push('/multiform')}>Get Started</Button>

      </section>
      <section className="images">
        <div className="cards">
          <div className="card1">
            {/*<section>*/}
              <iframe width="560" height="315" src="https://www.youtube.com/embed/JiXgXuHrNgo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            {/*</section>*/}
            {/*<div className="img">
              <Group />
            </div>
            <h3>Quick Set Up</h3>*/}
          </div>
          {/*<div className="card2">
            <img src={vector} alt="" />
            <h3>Lorem ipsum dolor</h3>
          </div>*/}

        </div>
        <div className="hero-image">
          {/*<img src="./../img/Group 8586.svg" alt="" />*/}
          <Group8586 />
        </div>


      </section>

    </div>
  )
}
export default Home
