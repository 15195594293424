import React, { Component, useState, useEffect  } from 'react';
import { Button } from 'react-bootstrap';
import history from './../../history';

import './ContactInfo.css'

import { ReactComponent as Frame } from './../../img/Frame.svg';

var debug = false;

export class Contact extends React.Component {
      constructor(props) {
          super(props);
          this.isComplete = false;
          this.handleChange = this.props.handleChange.bind(this);

      }

      isFormValid = (event, object, type) => {
          this.props.handleChange(event, object, type);

          console.log("Is form valid: " + this.isComplete)

          const {firstname, lastname, title, email, phone} =  object;

          console.log(firstname)
          console.log(lastname)
          console.log(title)
          console.log(email)
          console.log(phone)

          this.isComplete = false;
          if (firstname && lastname && title && email && (phone && phone.length >= 14)) {
            this.isComplete = true;
          }

          console.log(this.isComplete)
          this.props.checkValid(this.isComplete)

      }


      render() {
        console.log("What is the count ContactInfo: " + this.errorCount)
        if (this.props.currentStep !== this.props.step) {
          return null;
        }
        else {
          this.props.checkValid(this.isComplete)
        }

        return (

          <div className="form-group">
              <header>
                  <Frame />
              </header>
              <section className="contact_intro">
                  <h1>Let's Get to Know You</h1>
                  <h4>
                      Who are the right people contact in your organization?<br />
                      We are not just validating devices. We are building partnerships.
                  </h4>
              </section>
              <section className="contact_form">
                  <Form
                      title="Your Contact Information"
                      displayErrors={this.props.displayErrors}
                      multiFormData={this.props.multiFormData.contactForm}
                      onChange={event => {this.isFormValid(event, this.props.multiFormData.contactForm, 'contactForm')}}
                  />
                  {/*displayErrors={this.props.displayErrors}*/}
                  <Form
                      title="Primary Technical Contact"
                      multiFormData={this.props.multiFormData.technicalContactForm}
                      optional={true}
                      onChange={event => {this.handleChange(event, this.props.multiFormData.technicalContactForm, 'technicalContactForm')}}
                  />
                  {/*displayErrors={this.props.displayErrors}*/}
                  <Form
                      title="Support Contact"
                      multiFormData={this.props.multiFormData.supportContactForm}
                      optional={true}
                      onChange={event => {this.handleChange(event, this.props.multiFormData.supportContactForm, 'supportContactForm')}}
                  />
                  {/*displayErrors={this.props.displayErrors}*/}
                  <Form
                      title="Primary Sales Contact"
                      multiFormData={this.props.multiFormData.salesContactForm}
                      optional={true}
                      onChange={event => {this.handleChange(event, this.props.multiFormData.salesContactForm, 'salesContactForm')}}
                  />
                  {/*displayErrors={this.props.displayErrors}*/}
                  <Form
                      title="Primary Marketing Contact"
                      multiFormData={this.props.multiFormData.marketingContactForm}
                      optional={true}
                      onChange={event => {this.handleChange(event, this.props.multiFormData.marketingContactForm, 'marketingContactForm')}}
                  />
              </section>

          </div>
        )
      }
}

export default Contact;

const phoneValidator = (value) => {
  console.log("Test phone validator: " + value)
  return (value.length < 14) ? "Please enter a valid phone number." : "";
};
const emailValidator = (value) => (
  console.log("Test email validator"),
  new RegExp(/\S+@\S+\.\S+/).test(value) ? "" : "Please enter a valid email."
);
const requiredValidator = (value) => {
  console.log("Test validator")
  return value ? "" : "This field is required";
}

const ValidationMessage = ({ displayError, visited, validationMessage }) => {
  console.log(displayError, visited, validationMessage)
  return (
    <>
      {(displayError || visited) && validationMessage != '' && <div className="error">* {validationMessage}</div>}
    </>
  );

};

export class Form extends React.Component {
    constructor(props) {
     super(props);
    }

    componentWillReceiveProps(nextProps) {
      this.setState({
        displayError: nextProps.displayErrors
      })
    }

    // console.log(props.validator[0](props.multiFormData.firstname))
    render() {
      return(
        <div className="yourcontact_form">
            <h2>{this.props.title}</h2>
            <Field
                label="First Name"
                name="firstname"
                placeholder="First Name..."
                displayErrors={this.props.displayErrors}
                value={debug ? "Mike" : this.props.multiFormData.firstname}
                onChange={this.props.onChange}
                optional={this.props.optional}
            />
            <Field
                label="Last Name"
                name="lastname"
                placeholder="Last Name..."
                displayErrors={this.props.displayErrors}
                value={debug ? "Jackson" : this.props.multiFormData.lastname}
                onChange={this.props.onChange}
                optional={this.props.optional}
            />
            <Field
                label="Title"
                name="title"
                placeholder="Title..."
                displayErrors={this.props.displayErrors}
                value={debug ? "Bouncer" : this.props.multiFormData.title}
                onChange={this.props.onChange}
                optional={this.props.optional}
            />
            <Field
                label="Email"
                name="email"
                placeholder="Email..."
                displayErrors={this.props.displayErrors}
                validator={emailValidator}
                value={debug ? "magic.michael@exotic.com" : this.props.multiFormData.email}
                onChange={this.props.onChange}
                optional={this.props.optional}
            />
            <Field
                label="Phone"
                name="phone"
                placeholder="Phone..."
                displayErrors={this.props.displayErrors}
                validator={phoneValidator}
                value={debug ? "(555) 555-5555": this.props.multiFormData.phone}
                onChange={this.props.onChange}
                optional={this.props.optional}
            />
        </div>
      )
    }
}

export class Field extends React.Component {
  constructor(props) {
    super(props);
    this.validationMessage = '';
    this.state = {
      visited: false,
      displayError: this.props.displayErrors,
      validationMessage: null
    }
    // this.validator = this.props.validator.bind();
    console.log(this.state.displayError, this.props.displayErrors);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      displayError: nextProps.displayErrors
    })
  }

  handleBlur = () => {
    console.log(this.state.visited)
    this.setState({
      visited: true,
    });
  }

  validate = (value) => {
    // validate email or phone number
    if (!this.props.optional) {
      if (value == "") {
        return requiredValidator(value);
      } else if(this.props.validator) {
        return this.props.validator(value);
      }
      return requiredValidator(value);
    } else {
      if (this.props.validator) {
        return this.props.validator(value)
      } else {
        return "";
      }
    }
  }

  render () {
    var optional = (this.props.optional) ? "(Optional) " : "(Required) ";


    return (
      <>
          <label for={this.props.name}>{this.props.label}</label>
          <input
            type="text"
            name={this.props.name}
            placeholder={optional + this.props.placeholder}
            value={this.props.value}
            onChange={this.props.onChange}
            onBlur={this.handleBlur}
          />
          <ValidationMessage
              displayError={this.state.displayError}
              visited={this.state.visited}
              validationMessage={this.validate(this.props.value)}
          />

      </>
  )};
}
