import React, { Component } from 'react';
import history from './../history';

import ContactInfo from "./ContactInfo/ContactInfo";
import CompanyInfo from "./CompanyInfo/CompanyInfo";
import DeviceInfo from "./DeviceInfo/DeviceInfo";
import HardwareKeysInfo from "./HardwareKeysInfo/HardwareKeysInfo";
import ValidationCode from "./ValidationCode/ValidationCode";

import keysProfile from './keysProfile.js'
import debugProfile from './debugProfile.js'

import config from './../configData.js'

const url = config.url;
const debug = config.debug;

class MultiForm extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState();
    this.maxSteps = 4;
    this.errorCount = 0;
    this.isCurrentStepValid = false;
    // this.displayErrors = false;

    console.log(config.url)
  }

  // terms: false,
  getInitialState() {
    var initialState = null;
    if (debug){
      initialState = debugProfile
    } else {
      initialState = {
        displayErrors: false,
        multiform: {
          currentStep: 1,
          contactForm: {
            firstname: '',
            lastname: '',
            title: '',
            email: '',
            phone: '',
          },
          technicalContactForm: {
            firstname: '',
            lastname: '',
            title: '',
            email: '',
            phone: '',
          },
          supportContactForm: {
            firstname: '',
            lastname: '',
            title: '',
            email: '',
            phone: '',
          },
          salesContactForm: {
            firstname: '',
            lastname: '',
            title: '',
            email: '',
            phone: '',
          },
          marketingContactForm: {
            firstname: '',
            lastname: '',
            title: '',
            email: '',
            phone: '',
          },
          companyInfo: {
            image: {
              name: '',
              originalname: '',
              mimetype: '',
              size: '',
              filename: '',
              path: ''
            },
            companyname: '',
            phone: '',
            email: '',
            description: '',
            website: ''
          },
          device: {
            version: '',
            manufacturer: '',
            model: '',
            description: '',
            os: '',
            deviceType: '',
            image: {
              originalname: '',
              mimetype: '',
              size: '',
              filename: '',
              path: ''
            },
            isMobileIronCertified: false,
            physicalKeys: false,
            validateVoice: false
          },
          validationCode: '',
          terms: '',
          keysProfile
        }
      }
    };
    console.log(initialState)
    return initialState;
  }

  _normalizeInput = (value, previousValue) => {
    // return nothing if no value
    console.log(value, previousValue)
    if (!value) return value;

    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    console.log(currentValue, cvLength)
    console.log(!previousValue, value.length > previousValue.length)

    // if (!previousValue || value.length > previousValue.length) {
      console.log("phone: " + currentValue);
      // returns: "x", "xx", "xxx"
      if (cvLength < 4) return currentValue;

      // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
      if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

      // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
      // return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
      if (cvLength < 11) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;

      if (cvLength < 12) return `+${currentValue.slice(0, 1)} (${currentValue.slice(1, 4)}) ${currentValue.slice(4, 7)}-${currentValue.slice(7, 11)}`

      if (cvLength < 13) return `+${currentValue.slice(0, 2)} ${currentValue.slice(2, 5)} ${currentValue.slice(5, 8)} ${currentValue.slice(8, 12)}`

      if (cvLength < 14) return `+${currentValue.slice(0, 3)} ${currentValue.slice(3, 6)} ${currentValue.slice(6, 9)} ${currentValue.slice(9, 13)}`

      return `+${currentValue.slice(0, 3)} ${currentValue.slice(3, 6)} ${currentValue.slice(6, 9)} ${currentValue.slice(9, 13)}`;
    // }
  }

  handleCheckButtons = (event, object, parent, type, index) => {
    const multiform = this.state.multiform;
    console.log("Check buttons")
    console.log(event)
    console.log(object)
    console.log(type)
    console.log(index)

    var key = event.target.name;
    var value = event.target.value;

    console.log(key + ": " + value)

    if (parent == "alpha") {
      value = !this.state.multiform.keysProfile.alpha[type][index].value
      multiform.keysProfile.alpha[type][index].value = value
    } else {
      value = !this.state.multiform.keysProfile[type][index].value
      multiform.keysProfile[type][index].value = value
    }

    this.setState({
      multiform: multiform
    })

  }

  handleCurrentStep = (isValid) => {
    this.isCurrentStepValid = isValid;
    console.log("is Current step valid: " + this.isCurrentStepValid + " " + isValid)
  }

  handleChange = (event, object, type) => {
    console.log("handleChange")
    const multiform = this.state.multiform;
    let { name, value } = event.target;


    console.log(name + ": " + value)
    console.log(object)
    console.log(multiform)
    console.log(object[name])
    console.log(multiform[type])

    if (name == "phone") {
      // format phone number
      multiform[type][name] = this._normalizeInput(value, this.state.multiform[type].phone) // TODO: Add Country code
      console.log(value)
    } else if (name == "physicalKeys") {
      // toggle checkbox
      multiform[type][name] = !this.state.multiform[type].physicalKeys
    } else if (name == "validateVoice") {
      // toggle checkbox
      multiform[type][name] = !this.state.multiform[type].validateVoice
    } else if (name == "isMobileIronCertified") {
      multiform[type][name] = !this.state.multiform[type].isMobileIronCertified
      console.log(multiform[type][name])
    } else if (name == "terms") {
      console.log(this.state.multiform[type])
      // toggle checkbox
      multiform[type] = !this.state.multiform[type]
      console.log(value)
    } else if (name == "type") {
      console.log(typeof(value))

    } else if (name == "file1") {
      multiform[type].image = event.target.files[0];

    } else {
      multiform[type][name] = value;
    }

    console.log(value)
    console.log(object)
    console.log(multiform)
    console.log(object[name])
    console.log(multiform[type])


    console.log(value)
    console.log(object)
    console.log(object[name])
    console.log(multiform[type][name])
    console.log(multiform[type])
    console.log(multiform)

    this.setState({
      multiform: multiform,
    })
  }

  handleSubmit = event => {
    console.log("handleSubmit")
    event.preventDefault()
    const { multiform } = this.state
    console.log(multiform)

    if(multiform.terms) {
      // const result = await uploadForm(multiForm, )
      const data = new FormData()
      data.append('data', JSON.stringify(multiform))
      data.append('file', multiform.companyInfo.image)
      data.append('file', multiform.device.image)

      console.log(JSON.parse(data.get("data")))

      const requestOptions = {
          method: 'POST',
          body: data
          // headers: { 'Content-Type': 'application/json' },
          // body: JSON.stringify(multiform)
      };
      console.log(requestOptions)
      fetch(url + '/api/validations/file', requestOptions)
              .then(async response => {
              const data = await response.json();

              console.log(data)

              // check for error response
              if (!response.ok) {
                  // get error message from body or default to response status
                  const error = (data && data.message) || response.status;
                  return Promise.reject(error);
              }
              console.log(debugProfile);
              // history.push("/finish/" + data.validationCode)
              this.setState({ // bad routing code
                multiform: this.state.multiform.currentStep = 1,
              }, () => {
                console.log(this.state)
                history.replace("/finish/" + data.validationCode)
              })

              // this.setState({ postId: data.id })
          })
          .catch(async error => {
              console.error(error)
              this.setState({ errorMessage: error.toString() });
              console.error('There was an error!', this.state.errorMessage);
              alert("One or more errors in form. Check that you have uploaded images.")
          });

    } else {
      console.log("Agree to terms")
    }

    console.log(multiform)
  }

  validateForm = (errors) => {
    let valid = true;
    console.log(errors)

    // Check if

    Object.values(errors).forEach(
      // if we have an error string set valid to false
      (val) => {
        console.log(val)
          Object.values(val).forEach(subVal => {
            console.log(subVal)
            subVal.length > 0 && (valid = false)
          })
      }
    );
    return valid;
  }

  _next = () => {
    let multiform = this.state.multiform

    console.log("WHAT'S THE COUNT: " + multiform.errorCount)

    console.log(this.errorCount)
    if (this.isCurrentStepValid || debug) {
      console.info('Valid Form')
      multiform.currentStep = multiform.currentStep >= this.maxSteps - 1? this.maxSteps: multiform.currentStep + 1
      this.setState({
        multiform: multiform,
        displayErrors: false
      })
    } else {
      console.error('Invalid Form')
      // this.displayErrors = true;
      console.log(this.state.displayErrors)
      // this.displayErrors = true;
      this.setState({
        displayErrors: true
      });
    }
    console.log(this.state.displayErrors)

  }

  _prev = () => {
    let multiform = this.state.multiform
    this.isCurrentStepValid = true;
    multiform.currentStep = multiform.currentStep <= 1? 1: multiform.currentStep - 1
    this.setState({
      multiform: multiform,
      displayErrors: false
    })
  }

  /*
  * the functions for our button
  */
  previousButton() {
    let currentStep = this.state.multiform.currentStep;
    if(currentStep !==1){
      return (
        <button
          className="float-left"
          type="button" onClick={this._prev}>
        Back
        </button>
      )
    }
    return null;
  }



  /*<section className="info">
    <Button variant="btn" onClick={() => history.push('/companyinfo')}>Continue</Button>
  </section>*/
  nextButton(){
    let currentStep = this.state.multiform.currentStep;
    if(currentStep < this.maxSteps){
      return (
        <button
          className="float-right"
          type="button" onClick={this._next}>
        Continue
        </button>
      )
    }
    return null;
  }

  render() {
    console.log(this.getInitialState())

    return (

      <div className="MultiForm">
        <React.Fragment>
        <h1>
          Ivanti | Wavelink <br />
          Device Self Validation Portal
        </h1>

        <form id="multiForm" onSubmit={this.handleSubmit}>
        {/*
          render the form steps and pass required props in
        */}
          <ContactInfo
            step={1}
            currentStep={this.state.multiform.currentStep}
            handleChange={this.handleChange}
            multiFormData={this.state.multiform}
            checkValid={this.handleCurrentStep}
            displayErrors={this.state.displayErrors}
          />
          {/*validator={[this.requiredValidator, this.emailValidator]}*/}
          {/*<Contact />*/}
          <CompanyInfo
            step={2}
            currentStep={this.state.multiform.currentStep}
            handleChange={this.handleChange}
            multiFormData={this.state.multiform}
            handlePhotoSelect={this.handlePhotoSelect}
            checkValid={this.handleCurrentStep}
            displayErrors={this.state.displayErrors}
          />
          <DeviceInfo
            step={3}
            currentStep={this.state.multiform.currentStep}
            handleChange={this.handleChange}
            multiFormData={this.state.multiform}
            handlePhotoSelect={this.handlePhotoSelect}
            checkValid={this.handleCurrentStep}
            displayErrors={this.state.displayErrors}
          />
          <HardwareKeysInfo
            step={4}
            currentStep={this.state.multiform.currentStep}
            handleChange={this.handleChange}
            multiFormData={this.state.multiform}
            handleCheckButtons={this.handleCheckButtons}
          />
          {/*<ValidationCode
            step={5}
            currentStep={this.state.multiform.currentStep}
            handleChange={this.handleChange}
            multiFormData={this.state.multiform}
          />*/}
          <div className="space-between">
              {this.previousButton()}
              {this.nextButton()}
          </div>
        </form>
        </React.Fragment>
      </div>
    );
  }
}
export default MultiForm;
