
/*****************************************************
 * Import global configs
 *****************************************************/

var isProd = true; // Prod: true; Localhost: false;
var isDebug = false; // debug settings: true; else: false;

var url = '';
if (isProd) {
  // url = "http://18.144.39.5";
  // url = "https://54.219.3.157";
  url = "https://rapidvalidation.ivanti.com";
} else {
  url = "http://localhost:4000";
}

const constData = {
    url,
    debug: isDebug,
};
export default constData;
