import React, { useState, useEffect  } from "react";
import { Link, Redirect  } from 'react-router-dom';
// import logoImg from "../img/logo.jpg";
import Frame from './../img/Frame.svg';
import { Card, Logo, Form, Input, Button, Error } from '../AuthForm/AuthForm';
import configData from './../configData.js'

import { useAuth } from "../context/auth";

const url = configData.url;

function Login(props) {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const { setAuthTokens } = useAuth();

  const referer = props.location.state.referer || '/';

  function postLogin() {
    var requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        usr: userName,
        pwd: password
      })
    }

    return fetch(url + "/api/auth/login", requestOptions)
      .then((response) => {
        const statusCode = response.status;
        const data = response.json();
        console.log(statusCode, data);
        return Promise.all([statusCode, data]);
      }).then((result) => {
          if (result[0] === 200) {
            console.log("Success: ", result[1].token)
            setAuthTokens(result[1].token);
            // setAuthTokens(result.data);
            setLoggedIn(true);
          } else {
            setIsError(true);
            console.log("Error: ", result[0])
          }
      }).catch(e => {
        setIsError(true);
        console.log("Catch: ", e)
      });
    console.log("Nothing")
  }

  if (isLoggedIn) {
    return <Redirect to={referer} />;
  }

  return (
    <Card>
      {/*<Logo src={logoImg} />*/}
      <Logo src={Frame} />
      <Form>
        <Input
          type="username"
          value={userName}
          onChange={e => {
            setUserName(e.target.value);
          }}
          placeholder="Username or Email"
        />
        <Input
          type="password"
          value={password}
          onChange={e => {
            setPassword(e.target.value);
          }}
          placeholder="password"
        />
        <Button onClick={postLogin}>Sign In</Button>
      </Form>
      <Link to="/signup">Don't have an account?</Link>
        { isError &&<Error>The username or password provided were incorrect!</Error> }
        {/*<Card>
          <Logo src={logoImg} />
          <Logo src={Frame} />
          <Form>
          <Input type="email" placeholder="email" />
          <Input type="password" placeholder="password" />
          <Button>Sign In</Button>
          </Form>
          <Link to="/signup">Don't have an account?</Link>
          </Card>*/}
    </Card>
  );
}

export default Login;
