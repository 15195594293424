import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import history from './../../history';

import './DeviceInfo.css'

import { ReactComponent as Frame } from './../../img/Frame.svg';
import { ReactComponent as Image } from './../../img/image.svg';

var debug = false;

 export class DeviceInfo extends React.Component {
    constructor(props) {
     super(props);

     this.isComplete = false;
     this.handleChange = this.props.handleChange.bind(this);
     this.osSelectOptions = [{id: "windows", value: "Windows"},
                             {id: "android", value: "Android"},
                             {id: "ios", value: "iOS"}];
     this.deviceTypeSelectOptions = [{id: "handheld", value: "Handheld"},
                           {id: "wearable", value: "Wearable"},
                           {id: "phone", value: "Phone"},
                           {id: "tablet", value: "Tablet"},
                           {id: "vehiclemount", value: "Vehicle Mount"}];
    }

    isFormValid = (event, object, type) => {
      this.props.handleChange(event, object, type);

      if (typeof this.props.multiFormData.device.image === "undefined" ) {
        this.props.multiFormData.device.image = {name: ""};
      }

      console.log("Is form valid: " + this.isComplete)


      const {version, manufacturer, model, description, os, deviceType} = this.props.multiFormData.device;
      const {name, size} = this.props.multiFormData.device.image || {};


      console.log(version)
      console.log(manufacturer)
      console.log(model)
      console.log(description)
      console.log(os)
      console.log(deviceType)
      console.log(name)
      console.log(size)

      this.isComplete = false;
      if (size && size > 400000) {
        alert("File size exeeds 400kb")
        this.props.multiFormData.device.image = {};
      } else {
        if (version && manufacturer && model && description && os && deviceType && name) {
          this.isComplete = true;
        }
      }

      console.log(this.isComplete)
      this.props.checkValid(this.isComplete)

    }

    render() {
      let hiddenInput = null;
      if (this.props.currentStep !== this.props.step) {
        return null;
      } else {
        this.props.checkValid(this.isComplete)
      }

      return (
        <div className="CompanyInfo">
            <header>
                <Frame />
            </header>
            <section className="contact_intro">
                <h1>Tell Us About Your Device</h1>
                <h4>
                    The device image and info will be posted to our website under the device details.
                </h4>
            </section>
            <section className="contact_form">
            <div className="yourcontact_form">
                <h2>Device Info</h2>
                <Field
                    label="Manufacturer"
                    name="manufacturer"
                    placeholder="Manufacturer..."
                    displayErrors={this.props.displayErrors}
                    value={this.props.multiFormData.device.manufacturer}
                    onChange={event => { this.isFormValid(event, this.props.multiFormData.device, 'device')} }
                />
                <Field
                    label="Model"
                    name="model"
                    placeholder="Model..."
                    displayErrors={this.props.displayErrors}
                    value={this.props.multiFormData.device.model}
                    onChange={event => { this.isFormValid(event, this.props.multiFormData.device, 'device')} }
                />
                <SelectField
                    label="OS"
                    name="os"
                    placeholder="OS..."
                    displayErrors={this.props.displayErrors}
                    options={this.osSelectOptions}
                    value={this.props.multiFormData.device.os}
                    onChange={event => { this.isFormValid(event, this.props.multiFormData.device, 'device')} }
                />
                <Field
                    label="OS Version"
                    name="version"
                    placeholder="OS Version..."
                    displayErrors={this.props.displayErrors}
                    value={this.props.multiFormData.device.version}
                    onChange={event => { this.isFormValid(event, this.props.multiFormData.device, 'device')} }
                />
                <SelectField
                    label="Type"
                    name="deviceType"
                    placeholder="Type..."
                    options={this.deviceTypeSelectOptions}
                    displayErrors={this.props.displayErrors}
                    value={this.props.multiFormData.device.deviceType}
                    onChange={event => { this.isFormValid(event, this.props.multiFormData.device, 'device')} }
                />
                <div className="yourcontact_form">
                    <label for="fname">Is this device MobileIron certified?</label>
                    <input
                        type="checkbox"
                        id="isMobileIronCertified"
                        name="isMobileIronCertified"
                        checked={this.props.multiFormData.device.isMobileIronCertified}
                        onChange={event => {this.props.handleChange(event, this.props.multiFormData.device, 'device')}}
                    />
                </div>
                <DescField
                  label="Description"
                  name="description"
                  cols="30"
                  rows="10"
                  maxlength="1000"
                  placeholder="Device Description..."
                  displayErrors={this.props.displayErrors}
                  value={this.props.multiFormData.device.description}
                  onChange={event => { this.isFormValid(event, this.props.multiFormData.device, 'device')} }
                />
            </div>
            <div className="yourcontact_form">
              <h2>Device Image</h2>
              <ImageUpload
                name={this.props.multiFormData.device.image.name}
                displayErrors={this.props.displayErrors}
                onChange={event => { this.isFormValid(event, this.props.multiFormData.device.image, 'device') }}
              />
            </div>

            {/*TODO: if these checkboxes are required they must be handled in the same maner as the components below so as to keep the error display handling the same*/}
            {/*<div className="yourcontact_form">
            <h2>Extra Info</h2>
            <label for="fname">Does it have any physical input keys?</label>
            <input
            type="checkbox"
            id="physicalKeys"
            name="physicalKeys"
            checked={props.multiFormData.device.physicalKeys}
            onChange={event => {props.handleChange(event, props.multiFormData.device, 'device')}}
            />
            <label for="description">Do you want to validate this device with Speakeasy Voice recognition as well?</label>
            <input
            type="checkbox"
            id="validateVoice"
            name="validateVoice"
            checked={props.multiFormData.device.validateVoice}
            onChange={event => {props.handleChange(event, props.multiFormData.device, 'device')}}
            />
            </div>*/}

            </section>

        </div>
      )
    }
}

export default DeviceInfo;

const requiredValidator = (value) => {
  console.log("Test validator")
  return value ? "" : "This field is required";
}

const ValidationMessage = ({ displayError, visited, validationMessage }) => {
  console.log(displayError, visited, validationMessage)
  return (
    <>
      {(displayError || visited) && validationMessage != '' && <div className="error centerText">* {validationMessage}</div>}
    </>
  );

};

export class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.validationMessage = '';
    this.state = {
      visited: false,
      displayError: this.props.displayErrors
    }
    console.log(this.state.displayError, this.props.displayErrors)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      displayError: nextProps.displayErrors
    })
  }

  handleBlur = () => {
    console.log(this.state.visited)
    this.setState({
      visited: true
    });
  }


  render() {
    return (
      <>
        <div className="img center">
          <Image />
        </div>
        <div className="fileName centerText" >
          {this.props.name}
        </div>
        <ValidationMessage
            displayError={this.state.displayError}
            visited={this.state.visited}
            validationMessage={requiredValidator(this.props.name)}
        />
        <div class="center">
          <button
            type="button"
            variant="outline"
            variantColor="blue"
            loadingText="Uploading..."
            onClick={() => this.hiddenInput.click()}
            onBlur={this.handleBlur}>
            Upload
          </button>
          <input
            hidden
            type="file"
            name="file1"
            ref={el => this.hiddenInput = el}
            onChange={this.props.onChange}
          />
          <div class="centerText">
            (Max File Size: 400kb)
          </div>
        </div>
      </>
    );
  }
}

export class SelectField extends React.Component {
  constructor(props) {
    super(props);
    this.validationMessage = '';
    this.state = {
      visited: false,
      displayError: this.props.displayErrors
    }
    console.log(this.state.displayError, this.props.displayErrors)



  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      displayError: nextProps.displayErrors
    })
  }



  handleBlur = () => {
    console.log(this.state.visited)
    this.setState({
      visited: true
    });
  }

  render () {

    const options = this.props.options.map((option) =>
      <option id={option.id} value={option.value}>{option.value}</option>
    )

    return (
      <>
          <label for={this.props.name}>{this.props.label}</label>
          <select
            id="deviceType"
            name={this.props.name}
            value={this.props.value}
            onChange={this.props.onChange}
          >
              <option value="" selected disabled hidden>{this.props.placeholder}</option>
              {options}
          </select>

          <ValidationMessage
              displayError={this.state.displayError}
              visited={this.state.visited}
              validationMessage={requiredValidator(this.props.value)}
          />
      </>
  )};
}

export class Field extends React.Component {
  constructor(props) {
    super(props);
    this.validationMessage = '';
    this.state = {
      visited: false,
      displayError: this.props.displayErrors
    }
    console.log(this.state.displayError, this.props.displayErrors)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      displayError: nextProps.displayErrors
    })
  }

  handleBlur = () => {
    console.log(this.state.visited)
    this.setState({
      visited: true
    });
  }

  render () {
    return (
      <>
          <label for={this.props.name}>{this.props.label}</label>
          <input
            type="text"
            name={this.props.name}
            placeholder={this.props.placeholder}
            value={this.props.value}
            onChange={this.props.onChange}
            onBlur={this.handleBlur}
          />
          <ValidationMessage
              displayError={this.state.displayError}
              visited={this.state.visited}
              validationMessage={requiredValidator(this.props.value)}
          />
      </>
  )};
}

export class DescField extends React.Component {
  constructor(props) {
    super(props);
    this.validationMessage = '';
    this.state = {
      visited: false,
      displayError: this.props.displayErrors
    }
    console.log(this.state.displayError, this.props.displayErrors)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      displayError: nextProps.displayErrors
    })
  }

  handleBlur = () => {
    console.log(this.state.visited)
    this.setState({
      visited: true
    });
  }



  render () {
    const RemainingCharacters = () => {
      return <div className="charRemaining"><span >{this.props.value.length}/{this.props.maxlength}</span></div>
    }
    return (
      <>
          <label for={this.props.name}>{this.props.label}</label>
          <ValidationMessage
              displayError={this.state.displayError}
              visited={this.state.visited}
              validationMessage={requiredValidator(this.props.value)}
          />
          <textarea
            name={this.props.name}
            cols={this.props.cols}
            rows={this.props.rows}
            maxlength={this.props.maxlength}
            placeholder={this.props.placeholder}
            value={this.props.value}
            onChange={this.props.onChange}
            onBlur={this.handleBlur}
          />
          <RemainingCharacters/>

      </>
  )};
}
