import React, { Component, useState, useEffect  } from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';

import history from './../history';
import "./Admin.css";


import configData from './../configData.js'

const url = configData.url;

export default class Admin extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        validations: [],
        toggleState: true
      }
      // this.toggleState = true;
      this.handleToggleChange = this.handleToggleChange.bind(this)
      this.getValidationsState();
    }

    async getValidationsState() {
      this._getValidations().then(async response => {
        console.log(response)
        this.setState({
          validations: response,
        }, () => {
          console.log(this.state.validations);
        })
        console.log(this.state.validations);

      })

    }

    handleToggleChange() {
      // this.setState({toggleState: !this.state.toggleState})
      // this.toggleState = !this.toggleState
      this.getValidationsState();
    }

    _getValidations = () => {
        var toggle = !this.state.toggleState;
        return fetch(url + "/api/pending/?v=" + toggle.toString())
        .then(async response => {
          const data = await response.json();

          console.log(data)

          // check for error response
          if (!response.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }

          // this.setState({
          //   validations: data
          // })
          // validationCode = data.validationCode
          // this.setState({ postId: data.id })\
          // setValue(data)
          // setSuccess(true)
          this.setState({
            toggleState: toggle
          })

          console.log(data)
          return (data)
        })
        .catch(async error => {
          console.error(error)
          // this.setState({ errorMessage: error.toString() });
          // console.error('There was an error!', this.state.errorMessage);
          // setValue("Error!!!")
          // setSuccess(false)
          this.setState({
            toggleState: !toggle
          })

          return (0)
        });
    }

    toggleValidationsButton() {

      return(
          <>
            <h1>{!this.state.toggleState ? "Unvalidated Devices" : "Validated Devices"}</h1>
            <ButtonGroup toggle className="d-flex flex-wrap">
                <ToggleButton
                    className="p-3"
                    type="checkbox"
                    variant="light"
                    name="toggleState"
                    checked={this.state.toggleState}
                    value={this.state.toggleState}
                    onChange={() => {this.handleToggleChange()}}
                    >
                    <h2>{this.state.toggleState ? "Show Unvalidated Devices" : "Show Validated Devices"}</h2>
                </ToggleButton>
            </ButtonGroup>
          </>
      )
    }

    validationsPendingList() {
        var items = this.state.validations;
        console.log(this.state.validations)
        console.log(items)
        var itemsList = [];
        for (var item in items) {
          itemsList.push(<Item item={items[item]}/>)
        }
        console.log(itemsList)
        return(<>{itemsList}</>)
    }

    componentDidMount() {
        this.getValidationsState();
    }

    render() {
      var validations = this.state.validations;
      // if (this.state.validations.length <= 0) {
      console.log(validations)
      return (
        <div>
          {this.toggleValidationsButton()}
          <section className="validation_list">
            <ItemList validations={this.state.validations} />


          </section>
        </div>
      )

    };
  // }
}



export class ItemList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validations: this.props.validations
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      validations: nextProps.validations
    })
    console.log(nextProps.validations)
  }

  render() {
    return (
      <>
        {this.props.validations.map((item,index)=>
            <Item key={index} item={item} />
        )}
      </>
    )
  }
}

export class Item extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        item: this.props.item
      }
      console.log(this.props.item)
    }

    componentWillReceiveProps(nextProps) {
      this.setState({
        item: nextProps.item
      })

    }

    async updateValidation(validation) {
        var item = this.state.item;
        console.log(item.multiform)
        console.log(url + '/api/validations/code/' + item.multiform.validationCode.toString())

        var requestBody = {
          code: item.multiform.validationCode,
          validated: validation
        }
        console.log(requestBody)
        var requestOptions = {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestBody)
        }
        console.log(requestOptions)
        return fetch(url + '/api/validations/code/' + item.multiform.validationCode.toString(), requestOptions)
                .then(async response => {
                  const data = await response.json();
                  console.log(data);
                  return(data);
                })
                .catch(async error => {
                  console.error(error);
                  console.error('There was an error!');
                  // alert("One or more errors in form. Check that you have uploaded images.")
                  return(error);
                });
    }

    async putUpdate(body, successCallback, errorCallback) {
        console.log("PutUpdate");
        console.log(body);

        var requestOptions = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        }
        console.log(this.requestOptions)

        return fetch(url + '/api/update/' + body.id.toString(), requestOptions)
                .then(async response => {
                  const data = await response.json();

                  console.log(data)
                  return(data)
                })
                .catch(async error => {
                  console.error(error)
                  this.setState({ errorMessage: error.toString() });
                  console.error('There was an error!');
                  // alert("One or more errors in form. Check that you have uploaded images.")
                  return(error);
                });



    }


    _validateRequest = () => {
        var item = this.state.item;

        if(item.testResults !== undefined){
            item.multiform.validated = true; // TODO: change this to true
            // Validate the device and get shortened JSON blob
            this.updateValidation(item.multiform.validated).then(async response => {
                console.log(response);
                // var shortJson = await response;
                //
                // this.putUpdate(shortJson.shortJson).then(async res => {
                //     console.log(res)
                //
                // })
            })

        } else {
            item.multiform.validated = false;
            alert("Test Results Not set by user!")

        }

        this.setState({
            item: item
        })
    }

    _unvalidateRequest = () => {
      var item = this.state.item;
      if(item.testResults !== undefined){
          item.multiform.validated = false;
          // const data = new FormData()
          // data.append('data', );
          // '{"id": "QWERTYU", "isArchived": true}'

          this.updateValidation(item.multiform.validated).then(async response => {
              console.log(response)
              // var hidden = {
              //     id: item.multiform.validationCode,
              //     isArchived: true
              // }
              // this.putUpdate(hidden).then(async response => {
              //     console.log(response)
              //
              // })
          })

      } else {
          item.multiform.validated = true;
          alert("Test Results Not set by user!")

      }

      this.setState({
          item: item
      })
    }

    validateButton(){
      var item = this.state.item;
      if(!item.multiform.validated){
        return (
          <button
            className="float-right"
            type="button" onClick={this._validateRequest}>
          Validate
          </button>
        )
      } else {
        return (
          <button
            className="float-right"
            type="button" onClick={this._unvalidateRequest}>
          Unvalidate
          </button>
        )
      }
      return null;
    }

    scanType() {
      var item = this.state.item;
      var testResultsSet = "false";
      if(item.testResults !== undefined){
          testResultsSet = "true";
      }
      return (<h2>Test Results Set: {testResultsSet}</h2>);
    }

    render() {
      var validationCode = (this.state.item.multiform.validationCode === null) ? "null": this.state.item.multiform.validationCode;
      var companyName = (this.state.item.multiform.companyInfo === null || this.state.item.multiform.companyInfo.companyname === null) ? "null": this.state.item.multiform.companyInfo.companyname;
      var deviceModel = (this.state.item.multiform.device === null || this.state.item.multiform.device.model === null) ? "null": this.state.item.multiform.device.model;
      if (companyName != "null" && deviceModel != "null") {
        return (
          <section className="item">
            {this.validateButton()}
            <h2>{validationCode} - {companyName} - {deviceModel}</h2>
            <div>
              <a href={this.state.item.multiform.companyInfo.image.url  } target="_blank">
                <img width="220" height="250" border="0" align="center"  src={this.state.item.multiform.companyInfo.image.url}/>
              </a>
              <a href={this.state.item.multiform.device.image.url} target="_blank">
                <img width="220" height="250" border="0" align="center"  src={this.state.item.multiform.device.image.url}/>
              </a>
            </div>
            {this.scanType()}
            {JSON.stringify(this.state.item)}
          </section>
        )
      } else {
        return (
          <section className="item">
            {this.validateButton()}
            <h2>{validationCode} - {companyName} - {deviceModel}</h2>
            {this.scanType()}
            {JSON.stringify(this.state.item)}
          </section>

        )

      }
    }

}
