import keysProfile from './keysProfile.js'
const debugData = {
  displayErrors: false,
  multiform: {
    currentStep: 1,
    contactForm: {
      firstname: 'Mike',
      lastname: 'Jackson',
      title: 'Intern',
      email: 'mjackson@sample.com',
      phone: '+1 (555) 555-5555',
    },
    technicalContactForm: {
      firstname: 'Toby',
      lastname: 'Flanigan ',
      title: 'Technical Contact',
      email: 'tflanigan@sample.com',
      phone: '+1 (555) 555-5555',
    },
    supportContactForm: {
      firstname: 'Bill',
      lastname: 'Lumbergh',
      title: 'Support',
      email: 'blumbergh@sample.com',
      phone: '+1 (555) 555-5555',
    },
    salesContactForm: {
      firstname: 'Tina',
      lastname: 'Belcher',
      title: 'Sales',
      email: 'tbelcher@sample.com',
      phone: '+1 (555) 555-5555',
    },
    marketingContactForm: {
      firstname: 'Moby',
      lastname: 'Richardson',
      title: 'Marketing',
      email: 'mrichardson@sample.com',
      phone: '+1 (555) 555-5555',
    },
    companyInfo: {
      image: {
        name: '',
        originalname: '',
        mimetype: '',
        size: '',
        filename: '',
        path: ''
      },
      companyname: 'Company Corp.',
      description: 'We sell stuff and things',
      website: 'www.companycorp.com/compcorp',
      phone: '+1 (444) 444-4444',
      email: 'compcorp@compcorp.biz',
    },
    device: {
      version: '1.0.90',
      manufacturer: 'Samsing',
      model: 'TI8-101',
      description: 'Company handheld with physical keys',
      os: 'Android',
      deviceType: 'Handheld',

      image: {
        originalname: '',
        mimetype: '',
        size: '',
        filename: '',
        path: ''
      },
      isMobileIronCertified: false,
      physicalKeys: false,
      validateVoice: false
    },
    validationCode: '',
    terms: '',
    keysProfile
  }
}
export default debugData;
