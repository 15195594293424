import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import history from './../history';
import "./Finish.css";

import { ReactComponent as Frame } from './../img/Frame.svg';
import { ReactComponent as Group8586 } from './../img/Group 8586.svg';
import { ReactComponent as Group } from './../img/Group.svg';
// import { ReactComponent as QRCode } from './../img/QRCode.svg';

import vector from './../img/Vector.png';
import PlayStoreCode from './../img/PlayStorebarcode.gif';
import DeviceValidationCode from './../img/DeviceValidationBarcode.png';
import VelocityARMCode from './../img/VelocityARMapkbarcode.gif';
import VelocityX86Code from './../img/VelocityX86apkbarcode.gif';

import config from './../configData.js'

const url = config.url;

// Create App component
function Finish(props) {

  const [value, setValue] = useState(0);
  const [isSuccess, setSuccess] = useState(false);

  console.log(value)
  console.log(setValue)
  const userID = props.match.params.id;
  // var validationCode = '';
  // console.log(userID)

  fetch(url + "/api/validations/" + userID.toString())
           .then(async response => {
           const data = await response.json();

           console.log(data.validationCode)

           // check for error response
           if (!response.ok) {
               // get error message from body or default to response status
               const error = (data && data.message) || response.status;
               return Promise.reject(error);
           }
           // validationCode = data.validationCode
           // this.setState({ postId: data.id })\
           setValue(data.validationCode)
           setSuccess(true)

           return (0)
       })
       .catch(async error => {
           console.error(error)
           // this.setState({ errorMessage: error.toString() });
           // console.error('There was an error!', this.state.errorMessage);
           setValue("Error!!!")
           setSuccess(false)

           return (0)
       });

  return (<Validation validationCode={value} isSuccess={isSuccess} />)


}

export default Finish

function Validation(props) {
  if (props.isSuccess) {

    return (
        <div className="app">
            <Frame className="Logo" />
            <section className="success">
                  <h1>Success!</h1>
                  <h2>We're Ready to Validate Your Device!</h2>
            </section>
            <section className="validation_steps">
              <section className="step1">
                  <h2>Step 1</h2>
                  <h3>Write Down Your Validation Code <b>(Don't lose this code.)</b></h3>
                  <div className="code">
                      <h1>{props.validationCode}</h1>
                  </div>
                  <h3>You will be asked for this code when you start the device validation.</h3>
              </section>
              <section className="step2">
                  <h2>Step 2</h2>
                  <h3>Scan this QR code to download the velocity app from the PlayStore</h3>
                  <div className="qrcodes">
                      <img className="QR" alt="QR Code" src={PlayStoreCode}/>
                      <h3><a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.wavelink.velocity&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">PlayStore</a></h3>
                  </div>
                  {/*<div className="playstore">
                      <a href='https://play.google.com/store/apps/details?id=com.wavelink.velocity&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                  </div>*/}
                  {/*<img className="QR" src="./img/QR Code.svg" alt="QR Code" />*/}
                  <h3>OR Download the latest Velocity Client below: </h3>
                  {/*<h3>OR Download the latest Velocity Client <a href="http://ec2-184-169-239-195.us-west-1.compute.amazonaws.com/downloads/velocity_client/Velocity.apk">Here</a> (Android ARM ) or <a href="http://ec2-184-169-239-195.us-west-1.compute.amazonaws.com/downloads/velocity_client/Velocity_x86.apk">Here</a> (Android x86)</h3>*/}
                  <div className="codes">
                      <div className="qrcodes">
                          <img className="QR" alt="QR Code" src={VelocityARMCode}/>
                          <h3><a target="_blank" rel="noopener noreferrer" href="https://wavelinksolutions.ivanti.com/velocity/VelocityARM.apk">Velocity Client (Android ARM)</a></h3>
                      </div>
                      <div className="qrcodes">
                          <img className="QR" alt="QR Code" src={VelocityX86Code}/>
                          <h3><a target="_blank" rel="noopener noreferrer" href="https://wavelinksolutions.ivanti.com/velocity/VelocityX86.apk">Velocity Client (Android x86)</a></h3>
                      </div>
                  </div>
              </section>
              <section className="step3">
                  <h2>Step 3</h2>
                  <h3>Download the Validation Profile:</h3>
                  <div className="qrcodes">
                      {/* <img className="QR" alt="QR Code" src={DeviceValidationCode}/> */}
                      <img className="QR" alt="QR Code" src="https://wavelinksolutions.ivanti.com/validation/Device_Validation_QRcode.jpeg"/>
                      {/* <h3>Download the <a target="_blank" rel="noopener noreferrer" href="https://wavelinksolutions.ivanti.com/validation/Device_Validation.wldep">Validation Profile</a></h3> */}
                      <h3>Download the <a target="_blank" rel="noopener noreferrer" href="https://wavelinksolutions.ivanti.com/validation/Device_Validation.wlcfg">Validation Profile</a></h3>
                  </div>
              </section>
              <section className="step4">
                  <h2>Step 4</h2>
                  {/*<h3>If you did not install using the playstore QR code view video below to <br /> learn how to install the Velocity Client Manaually</h3>*/}
                  <h3>Get the</h3>
                  <h3><a target="_blank" rel="noopener noreferrer" href="https://wavelinksolutions.ivanti.com/validation/barcodes.pdf">Device Validation Barcodes</a></h3>
                  {/*<img className="" alt="Device Validation Barcodes" src="https://wavelinksolutions.ivanti.com/validation/barcodes.pdf"/>*/}
                  {/*<iframe src="https://wavelinksolutions.ivanti.com/validation/barcodes.pdf" style="width:600px; height:500px;" frameborder="0"></iframe>*/}
                  <embed src="https://wavelinksolutions.ivanti.com/validation/barcodes.pdf" width="800px" height="1100px" />
                  {/*<iframe width="560" height="315" src="https://www.youtube.com/embed/6NS1NVTjj0s" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>*/}
              </section>
            </section>
        </div>
      )
  } else {
    return <ValidationError />
  }


}

function ValidationError(props) {
  return (
      <div className="app">
          <Frame className="Logo" />
          <section className="success">
              <h1>Oops!</h1>
              <h2>Something went wrong trying to validate your device</h2>
          </section>
          <section className="error">
              <Button variant="btn" onClick={() => history.replace('/')}>Back</Button>
          </section>
      </div>

  )
}
