import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import history from './../../history';

import './CompanyInfo.css'

import { ReactComponent as Frame } from './../../img/Frame.svg';
import { ReactComponent as Image } from './../../img/image.svg';

var debug = false;

export class CompanyInfo extends React.Component {
  constructor(props) {
    super(props);

    this.isComplete = false;
    this.handleChange = this.props.handleChange.bind(this);

  }

  isFormValid = (event, object, type) => {
    this.props.handleChange(event, object, type);

    if (typeof this.props.multiFormData.companyInfo.image === "undefined" ) {
      this.props.multiFormData.companyInfo.image = {name: ""};
    }

    console.log("Is form valid: " + this.isComplete)
    console.log( this.props.multiFormData.companyInfo.image )

    const {companyname, description, website, phone, email} = this.props.multiFormData.companyInfo
    const {name, size} = this.props.multiFormData.companyInfo.image || {};

    console.log(name)
    console.log(companyname)
    console.log(description)
    console.log(website)
    console.log(phone)
    console.log(email)

    this.isComplete = false;
    console.log(size)

    this.isComplete = false;
    if (size && size > 400000) {
      alert("File size exeeds 400kb")
      this.props.multiFormData.companyInfo.image = {};
    } else {
      if (companyname && name && description && website && phone && email) {
        this.isComplete = true;
      }
    }

    console.log(this.isComplete)
    this.props.checkValid(this.isComplete)

  }

  render() {
    this.hiddenInput = null;

    if (this.props.currentStep !== this.props.step) {
      return null;
    } else {
      console.log(this.props)
      this.props.checkValid(this.isComplete)
    }

    return (
      <div className="CompanyInfo">
        <header>
          <Frame />
        </header>
        <section className="contact_intro">
          <h1>Tell Us About Your Company</h1>
          <h4>
            Your logo and company info will be posted on our site along with the device details.
          </h4>
        </section>
        <section className="contact_form">
          <div className="yourcontact_form">
              <h2>Company Logo</h2>
              <ImageUpload
                name={this.props.multiFormData.companyInfo.image.name}
                displayErrors={this.props.displayErrors}
                onChange={event => { this.isFormValid(event, this.props.multiFormData.companyInfo.image, 'companyInfo') }}
              />
          </div>
          <div className="yourcontact_form">
          <h2>Company Info</h2>
            <Field
                label="Company Name"
                name="companyname"
                placeholder="Company Name..."
                displayErrors={this.props.displayErrors}
                value={this.props.multiFormData.companyInfo.companyname}
                onChange={event => { this.isFormValid(event, this.props.multiFormData.companyInfo, 'companyInfo')} }
            />
            <Field
                label="Company Website"
                name="website"
                placeholder="Company Website..."
                displayErrors={this.props.displayErrors}
                value={this.props.multiFormData.companyInfo.website}
                onChange={event => { this.isFormValid(event, this.props.multiFormData.companyInfo, 'companyInfo')} }
            />
            <Field
                label="Company Email"
                name="email"
                placeholder="Company Email..."
                displayErrors={this.props.displayErrors}
                validator={emailValidator}
                value={this.props.multiFormData.companyInfo.email}
                onChange={event => { this.isFormValid(event, this.props.multiFormData.companyInfo, 'companyInfo')} }
            />
            <Field
                label="Company Phone Number"
                name="phone"
                placeholder="Company Phone Number..."
                displayErrors={this.props.displayErrors}
                validator={phoneValidator}
                value={this.props.multiFormData.companyInfo.phone}
                onChange={event => { this.isFormValid(event, this.props.multiFormData.companyInfo, 'companyInfo')} }
            />
            {/*onChange={event => { this.props.handleChange(event, this.props.multiFormData.companyInfo, 'companyInfo') }}*/}
            <DescField
              label="Description"
              name="description"
              cols="30"
              rows="10"
              maxlength="1000"
              placeholder="Company Description..."
              displayErrors={this.props.displayErrors}
              value={this.props.multiFormData.companyInfo.description}
              onChange={event => { this.isFormValid(event, this.props.multiFormData.companyInfo, 'companyInfo')} }
            />
            {/*onChange={event => { this.props.handleChange(event, this.props.multiFormData.companyInfo, 'companyInfo') }}*/}
          </div>
        </section>
      </div>
    )
  }
}

export default CompanyInfo;


const phoneValidator = (value) => {
  console.log("Test phone validator: " + value)
  return (value.length < 14) ? "Please enter a valid phone number." : "";
};
const emailValidator = (value) => (
  console.log("Test email validator"),
  new RegExp(/\S+@\S+\.\S+/).test(value) ? "" : "Please enter a valid email."
);

const requiredValidator = (value) => {
  console.log("Test validator")
  return value ? "" : "This field is required";
}

const ValidationMessage = ({ displayError, visited, validationMessage }) => {
  console.log(displayError, visited, validationMessage)
  return (
    <>
      {(displayError || visited) && validationMessage != '' && <div className="error centerText">* {validationMessage}</div>}
    </>
  );

};


export class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.validationMessage = '';
    this.state = {
      visited: false,
      displayError: this.props.displayErrors
    }
    console.log(this.state.displayError, this.props.displayErrors)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      displayError: nextProps.displayErrors
    })
  }

  handleBlur = () => {
    console.log(this.state.visited)
    this.setState({
      visited: true
    });
  }


  render() {
    return (
      <>
        <div className="img center">
          <Image />
        </div>
        <div className="fileName centerText" >
          {this.props.name}
        </div>
        <ValidationMessage
            displayError={this.state.displayError}
            visited={this.state.visited}
            validationMessage={requiredValidator(this.props.name)}
        />
        <div class="center">
          <button
            type="button"
            variant="outline"
            variantColor="blue"
            loadingText="Uploading..."
            onClick={() => this.hiddenInput.click()}
            onBlur={this.handleBlur}>
            Upload
          </button>
          <input
            hidden
            type="file"
            name="file1"
            ref={el => this.hiddenInput = el}
            onChange={this.props.onChange}
          />
          <div class="centerText">
          (Max File Size: 400kb)
          </div>
        </div>
      </>
    );
  }
}

export class Field extends React.Component {
  constructor(props) {
    super(props);
    this.validationMessage = '';
    this.state = {
      visited: false,
      displayError: this.props.displayErrors,
      validationMessage: null
    }
    // this.validator = this.props.validator.bind();
    console.log(this.state.displayError, this.props.displayErrors);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      displayError: nextProps.displayErrors
    })
  }

  handleBlur = () => {
    console.log(this.state.visited)
    this.setState({
      visited: true,
    });
  }

  validate = (value) => {
    // validate email or phone number
    if (value == "") {
      return requiredValidator(value);
    } else if(this.props.validator) {
      return this.props.validator(value);
    }
    return requiredValidator(value);
  }
  render () {
    return (
      <>
          <label for={this.props.name}>{this.props.label}</label>
          <input
            type="text"
            name={this.props.name}
            placeholder={this.props.placeholder}
            value={this.props.value}
            onChange={this.props.onChange}
            onBlur={this.handleBlur}
          />
          <ValidationMessage
              displayError={this.state.displayError}
              visited={this.state.visited}
              validationMessage={this.validate(this.props.value)}
          />
      </>
  )};
}

export class DescField extends React.Component {
  constructor(props) {
    super(props);
    this.validationMessage = '';
    this.state = {
      visited: false,
      displayError: this.props.displayErrors
    }
    console.log(this.state.displayError, this.props.displayErrors)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      displayError: nextProps.displayErrors
    })
  }

  handleBlur = () => {
    console.log(this.state.visited)
    this.setState({
      visited: true
    });
  }

  render () {
    const RemainingCharacters = () => {
      return <div className="charRemaining"><span >{this.props.value.length}/{this.props.maxlength}</span></div>
    }
    return (
      <>
          <label for={this.props.name}>{this.props.label}</label>
          <ValidationMessage
              displayError={this.state.displayError}
              visited={this.state.visited}
              validationMessage={requiredValidator(this.props.value)}
          />
          <textarea
            name={this.props.name}
            cols={this.props.cols}
            rows={this.props.rows}
            maxlength={this.props.maxlength}
            placeholder={this.props.placeholder}
            value={this.props.value}
            onChange={this.props.onChange}
            onBlur={this.handleBlur}
          />
          <RemainingCharacters/>

      </>
  )};
}
