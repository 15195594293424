import React, { Component, Suspense, lazy, useState } from "react";
import { Switch, Route } from "react-router-dom";

import CompanyInfo from "./MultiForm/CompanyInfo/CompanyInfo";
import ContactInfo from "./MultiForm/ContactInfo/ContactInfo";
import Finish from "./Finish/Finish";
import PrivateRoute from './PrivateRoute';
import MultiForm from "./MultiForm/MultiForm";
import Home from "./Home/Home";
import Signup from "./Signup/Signup";
import Login from "./Login/Login";
import Admin from "./Admin/Admin";
import { AuthContext } from "./context/auth";
// import BetterAdmin from "./BetterAdmin/BetterAdmin";
const EditDevice = lazy(() => import('./EditDevice/EditDevice'));
const BetterAdmin = lazy(() => import('./BetterAdmin/BetterAdmin'));

// export default class Routes extends Component {
function Routes(){
    console.log(localStorage.getItem("tokens1"))
    const existingTokens = JSON.parse(localStorage.getItem("tokens1"));
    const [authTokens, setAuthTokens] = useState(existingTokens);
    localStorage.removeItem("tokens");

    const setTokens = (data) => {
      localStorage.setItem("tokens1", JSON.stringify(data));
      setAuthTokens(data);
    }

    // render() {
        return (
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/companyinfo" component={CompanyInfo} />
                <Route path="/contactinfo" component={ContactInfo} />
                <Route path="/multiform/" component={MultiForm}>
                    {/*<Route path="2" component={CompanyInfo} />*/}
                </Route>
                <Route path={`/finish/:id`} component={Finish} />

                <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Route path="/login" component={Login} />
                    <Route path="/signup" component={Signup} />
                    <PrivateRoute path={`/Admin`} component={Admin} />
                    <PrivateRoute path={`/BetterAdmin`} component={BetterAdmin} />
                    <PrivateRoute path={`/edit/:id?`} component={EditDevice} />
                  </Suspense>
                  {/*<Route path="/404" component={NotFoundPage} />
                  <Redirect to="/404" />*/}
                </AuthContext.Provider>
            </Switch>
        )
    // }
}

export default Routes;
