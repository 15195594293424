import React, { Component } from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import history from './../../history';

import './HardwareKeysInfo.css'

import { ReactComponent as Frame } from './../../img/Frame.svg';
import { ReactComponent as Image } from './../../img/image.svg';
// import $ from 'jquery'




function HardwareKeysInfo(props) {
      let hiddenInput = null;
      if (props.currentStep !== props.step) {
        return null
      }

      console.log(props.multiFormData.keysProfile.alpha)
      return (
        <div className="CompanyInfo">
            <header>
                <Frame />
            </header>
            <section class="contact_intro">
              <h1>Select all hardware keys available on the device</h1>
              <h4>
                  It is important we know about the physical keys available on the device. If your device does not have a physical keyboard then leave these unchecked.
              </h4>
            </section>
            <KeyGroup
                title="Alphanumeric"
                type="alpha"
                multiFormData={props.multiFormData.keysProfile.alpha}
                onChange={props.handleCheckButtons}
            />
            <KeyGroup
                title="5250"
                type="v5250"
                multiFormData={props.multiFormData.keysProfile.v5250}
                onChange={props.handleCheckButtons}
            />
            <KeyGroup
                title="3270"
                type="v3270"
                multiFormData={props.multiFormData.keysProfile.v3270}
                onChange={props.handleCheckButtons}
            />
            <KeyGroup
                title="VT"
                type="vt"
                multiFormData={props.multiFormData.keysProfile.vt}
                onChange={props.handleCheckButtons}
            />

            <section class="submit">
                <div className="terms_of_service">
                    <input type="checkbox" id="terms" name="terms" checked={props.multiFormData.terms} onClick={event => {props.handleChange(event, props.multiFormData, 'terms')}}/>
                    <label for="terms">By clicking submit, you are agreeing to the following <br /><a target="_blank" rel="noopener noreferrer" href="https://www.ivanti.com/company/legal/terms-of-use">Terms of Service</a></label>
                </div>
                <button className={`submit btn-block ${!props.multiFormData.terms ? 'disabled' : ''}`} id="btncheck" >Submit</button>
            </section>

        </div>
    );
}
export default HardwareKeysInfo

function KeyGroup(props) {

  let keys = props.multiFormData;
  let sectionList = [];

  if (Array.isArray(keys)) {
    sectionList.push(<SectionKeys section={keys} type={props.type} multiFormData={props.multiFormData} onChange={props.onChange} />)
  } else {
    for (var key in keys) {
      console.log(key)
      sectionList.push(<SectionKeys section={props.multiFormData[key]} parent={props.type} type={key} multiFormData={props.multiFormData} onChange={props.onChange} />)
    }
  }

  return(
      <section className="alphanumeric">
        <h2>{props.title}</h2>
        {sectionList}
      </section>
  );
}

function SectionKeys(props) {
  let keyList = [];
  let sectionData = props.section;

  console.log(props.section)

  for (var i = 0; i < props.section.length; i++) {
    keyList.push(<Key section={props.section} parent={props.parent} type={props.type} value={sectionData[i]} index={i} multiFormData={props.multiFormData} onChange={props.onChange} />)
  }
  return(
      <section className={props.section}>
          <ButtonGroup toggle className="d-flex flex-wrap">
              {keyList}
          </ButtonGroup>
      </section>
  );
}

function Key(props) {
  // console.log(props.value)
  return(
    <ToggleButton
        class="p-3"
        type="checkbox"
        variant="light"
        name={props.value.name}
        checked={props.value.value}
        value={props.value.value}
        onChange={event => {props.onChange(event, props.multiFormData, props.parent, props.type, props.index)}}
    >
        <h2>{props.value.name}</h2>
    </ToggleButton>



  );
}
