const keyData = {
    alpha: {
      lowercase: [
        { name: "a", value: false },
        { name: "b", value: false },
        { name: "c", value: false },
        { name: "d", value: false },
        { name: "e", value: false },
        { name: "f", value: false },
        { name: "g", value: false },
        { name: "h", value: false },
        { name: "i", value: false },
        { name: "j", value: false },
        { name: "k", value: false },
        { name: "l", value: false },
        { name: "m", value: false },
        { name: "n", value: false },
        { name: "o", value: false },
        { name: "p", value: false },
        { name: "q", value: false },
        { name: "r", value: false },
        { name: "s", value: false },
        { name: "t", value: false },
        { name: "u", value: false },
        { name: "v", value: false },
        { name: "w", value: false },
        { name: "x", value: false },
        { name: "y", value: false },
        { name: "z", value: false },
      ], uppercase: [
        { name: "A", value: false },
        { name: "B", value: false },
        { name: "C", value: false },
        { name: "D", value: false },
        { name: "E", value: false },
        { name: "F", value: false },
        { name: "G", value: false },
        { name: "H", value: false },
        { name: "I", value: false },
        { name: "J", value: false },
        { name: "K", value: false },
        { name: "L", value: false },
        { name: "M", value: false },
        { name: "N", value: false },
        { name: "O", value: false },
        { name: "P", value: false },
        { name: "Q", value: false },
        { name: "R", value: false },
        { name: "S", value: false },
        { name: "T", value: false },
        { name: "U", value: false },
        { name: "V", value: false },
        { name: "W", value: false },
        { name: "X", value: false },
        { name: "Y", value: false },
        { name: "Z", value: false },
      ], numbers_special:  [
        { name: "1", value: false },
        { name: "2", value: false },
        { name: "3", value: false },
        { name: "4", value: false },
        { name: "5", value: false },
        { name: "6", value: false },
        { name: "7", value: false },
        { name: "8", value: false },
        { name: "9", value: false },
        { name: "0", value: false },
        { name: "!", value: false },
        { name: "@", value: false },
        { name: "#", value: false },
        { name: "$", value: false },
        { name: "%", value: false },
        { name: "^", value: false },
        { name: "&", value: false },
        { name: "*", value: false },
        { name: "(", value: false },
        { name: ")", value: false },
        { name: "_", value: false },
        { name: "-", value: false },
        { name: "+", value: false },
        { name: "=", value: false },
        { name: "~", value: false },
        { name: "`", value: false },
        { name: "{", value: false },
        { name: "}", value: false },
        { name: "[", value: false },
        { name: "]", value: false },
        { name: "\\", value: false },
        { name: "|", value: false },
        { name: ";", value: false },
        { name: ":", value: false },
        { name: "'", value: false },
        { name: "\"", value: false },
        { name: ",", value: false },
        { name: ".", value: false },
        { name: "<", value: false },
        { name: ">", value: false },
        { name: "/", value: false },
        { name: "?", value: false },
        { name: "¦", value: false },
        { name: "¬", value: false },
        { name: "¢", value: false },
      ], commands: [
        { name: "F1", value: false },
        { name: "F2", value: false },
        { name: "F3", value: false },
        { name: "F4", value: false },
        { name: "F5", value: false },
        { name: "F6", value: false },
        { name: "F7", value: false },
        { name: "F8", value: false },
        { name: "F9", value: false },
        { name: "F10", value: false },
        { name: "F11", value: false },
        { name: "F12", value: false },
        { name: "F13", value: false },
        { name: "F14", value: false },
        { name: "F15", value: false },
        { name: "F16", value: false },
        { name: "F17", value: false },
        { name: "F18", value: false },
        { name: "F19", value: false },
        { name: "F20", value: false },
        { name: "F21", value: false },
        { name: "F22", value: false },
        { name: "F23", value: false },
        { name: "F24", value: false },
      ], modifiers: [
        { name: "Shift", value: false },
        { name: "Backspace", value: false },
        { name: "Enter", value: false },
        { name: "space", value: false },
      ]
    }, v5250: [
      { name: "Attn", value: false },
      { name: "Clear", value: false },
      { name: "DUP", value: false },
      { name: "DEL", value: false },
      { name: "Erase Input", value: false },
      { name: "Field -", value: false },
      { name: "Field +", value: false },
      { name: "Field Exit", value: false },
      { name: "Free Cursor", value: false },
      { name: "Help", value: false },
      { name: "Home", value: false },
      { name: "Ins", value: false },
      { name: "Print", value: false },
      { name: "Reset", value: false },
      { name: "Recall", value: false },
      { name: "Roll Up", value: false },
      { name: "Roll Down", value: false },
      { name: "RysReq", value: false },
    ], v3270: [
      { name: "Erase EOF", value: false },
      { name: "Field Mark", value: false },
      { name: "Key Click", value: false },
      { name: "New Line", value: false },
      { name: "Quiet", value: false },
      { name: "PA1", value: false },
      { name: "PA2", value: false },
      { name: "PA3", value: false },
    ], vt: [
      { name: "Find", value: false },
      { name: "Select", value: false },
      { name: "Insert", value: false },
      { name: "Remove", value: false },
      { name: "Prev Screen", value: false },
      { name: "Next Screen", value: false },
      { name: "^A", value: false },
      { name: "^B", value: false },
      { name: "^C", value: false },
      { name: "^D", value: false },
      { name: "^E", value: false },
      { name: "^F", value: false },
      { name: "^G", value: false },
      { name: "^H", value: false },
      { name: "^I", value: false },
      { name: "^J", value: false },
      { name: "^K", value: false },
      { name: "^L", value: false },
      { name: "^M", value: false },
      { name: "^N", value: false },
      { name: "^O", value: false },
      { name: "^P", value: false },
      { name: "^Q", value: false },
      { name: "^R", value: false },
      { name: "^S", value: false },
      { name: "^T", value: false },
      { name: "^U", value: false },
      { name: "^V", value: false },
      { name: "^W", value: false },
      { name: "^X", value: false },
      { name: "^Y", value: false },
      { name: "^Z", value: false },
      { name: "^^", value: false },
      { name: "^_", value: false },
      { name: "^\\", value: false },
      { name: "^]", value: false },
      { name: "^@", value: false },
    ]
}
export default keyData;
