import React from 'react';
import './App.css';
// import Navigation from './components/Navbar';
import Routes from './Routes';

import config from './configData.js'

const debug = config.debug;
if (!debug) {
    console.log = function () {};
    console.error = function () {};
    console.info = function () {};
}

function App() {
  return (
    <div className="App">
      {/*<Navigation />*/}
      <Routes />
    </div>
  );
}

export default App;
